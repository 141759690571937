@font-face {
  font-family: "TruHearing-Rounded";
  src:
    url("../assets/fonts/truhearing-rounded-regular-webfont.woff2") format("woff2"),
    url("../assets/fonts/truhearing-rounded-regular-webfont.woff") format("woff"),
    url("../assets/fonts/TruHearing-Rounded-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TruHearing-Rounded";
  src:
    url("../assets/fonts/truhearing-rounded-lightitalic-webfont.woff2") format("woff2"),
    url("../assets/fonts/truhearing-rounded-lightitalic-webfont.woff") format("woff"),
    url("../assets/fonts/TruHearing-Rounded-LightItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "TruHearing-Rounded";
  src:
    url("../assets/fonts/truhearing-rounded-light-webfont.woff2") format("woff2"),
    url("../assets/fonts/truhearing-rounded-light-webfont.woff") format("woff"),
    url("../assets/fonts/TruHearing-Rounded-Light.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "TruHearing-Rounded";
  src:
    url("../assets/fonts/truhearing-rounded-italic-webfont.woff2") format("woff2"),
    url("../assets/fonts/truhearing-rounded-italic-webfont.woff") format("woff"),
    url("../assets/fonts/TruHearing-Rounded-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "TruHearing-Rounded";
  src:
    url("../assets/fonts/truhearing-rounded-blackitalic-webfont.woff2") format("woff2"),
    url("../assets/fonts/truhearing-rounded-blackitalic-webfont.woff") format("woff"),
    url("../assets/fonts/TruHearing-Rounded-BlackItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "TruHearing-Rounded";
  src:
    url("../assets/fonts/truhearing-rounded-black-webfont.woff2") format("woff2"),
    url("../assets/fonts/truhearing-rounded-black-webfont.woff") format("woff"),
    url("../assets/fonts/TruHearing-Rounded-Black.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

a {
  text-decoration: none;
  color: #006ea8;
}
a:hover {
  text-decoration: underline;
  color: #005785;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
